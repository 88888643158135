import Hls from "hls.js";

let hls;

export const fetchVideoFromNetwork = (url) => {
  console.log("🚀 fetching videos from network", url);

  var dataStream = {
    video: [],
    audio: [],
  };
  if (url.includes(".m3u8")) {
    if (Hls.isSupported()) {
      hls = new Hls();

      // @ts-ignore
      window.hls = hls;

      hls.loadSource(url);
      //   hls.attachMedia(url);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        hls.on(Hls.Events.BUFFER_APPENDING, function (event, data) {
          console.log("🚀 apending");
          dataStream[data.type].push(data.data);
        });
      });
    }
  }

  console.log("🚀 fetching videos from network dataStream", dataStream);

  // Fetch the MP4 and WebM versions of the video using the fetch() function,
  // then expose their response bodies as blobs

  //   const mp4Blob = fetch(url).then((response) => {
  //     console.log("🚀 fetching videos from network response", response);

  //     return response.blob();
  //   });

  //   const webmBlob = fetch(url).then((response) =>
  //     response.blob()
  //   );

  // Only run the next code when both promises have fulfilled
  //   Promise.all([mp4Blob]).then((values) => {
  //     // display the video fetched from the network with displayVideo()
  //     // displayVideo(values[0], values[1], video.name);
  //     // store it in the IDB using storeVideo()
  //     // storeVideo(values[0], values[1], video.name);

  //     console.log("🚀 fetching videos from network values", values);
  //   });
};

function displayVideo(mp4Blob, webmBlob, title) {
  const section = document.querySelector("section");
  // Create object URLs out of the blobs
  const mp4URL = URL.createObjectURL(mp4Blob);
  const webmURL = URL.createObjectURL(webmBlob);

  // Create DOM elements to embed video in the page
  const article = document.createElement("article");
  const h2 = document.createElement("h2");
  h2.textContent = title;
  const video = document.createElement("video");
  video.controls = true;
  const source1 = document.createElement("source");
  source1.src = mp4URL;
  source1.type = "video/mp4";
  const source2 = document.createElement("source");
  source2.src = webmURL;
  source2.type = "video/webm";

  // Embed DOM elements into page
  section.appendChild(article);
  article.appendChild(h2);
  article.appendChild(video);
  video.appendChild(source1);
  video.appendChild(source2);
}

// Define the storeVideo() function
function storeVideo(mp4Blob, webmBlob, name) {
  let db;
  // Open transaction, get object store; make it a readwrite so we can write to the IDB
  const objectStore = db
    .transaction(["videos_os"], "readwrite")
    .objectStore("videos_os");
  // Create a record to add to the IDB
  const record = {
    mp4: mp4Blob,
    webm: webmBlob,
    name: name,
  };

  // Add the record to the IDB using add()
  const request = objectStore.add(record);

  request.addEventListener("success", () =>
    console.log("Record addition attempt finished")
  );
  request.addEventListener("error", () => console.error(request.error));
}

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("VideoDatabase", 1);
    request.onupgradeneeded = function (event: any) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("videos")) {
        db.createObjectStore("videos", { keyPath: "id" });
      }
    };
    request.onsuccess = function (event: any) {
      resolve(event.target.result);
    };
    request.onerror = function (event: any) {
      reject("Error opening database: " + event.target.errorCode);
    };
  });
}

export function saveVideoToIndexedDB(
  videoBlob: Blob,
  videoId: string
): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const db = await openDatabase();
    const transaction = db.transaction(["videos"], "readwrite");
    const store = transaction.objectStore("videos");
    const request = store.put({ id: videoId, blob: videoBlob });
    request.onsuccess = () => resolve();
    request.onerror = () => reject("Error saving video: " + request.error);
  });
}

export function getVideoFromIndexedDB(videoId: string): Promise<Blob> {
  return new Promise(async (resolve, reject) => {
    const db = await openDatabase();
    const transaction = db.transaction(["videos"], "readonly");
    const store = transaction.objectStore("videos");
    const request = store.get(videoId);
    request.onsuccess = function (event: any) {
      if (event.target.result) {
        resolve(event.target.result.blob);
      } else {
        reject("Video not found in IndexedDB");
      }
    };
    request.onerror = () => reject("Error fetching video: " + request.error);
  });
}

export function listDownloadedVideos(): Promise<
  { id: string; name: string }[]
> {
  return new Promise(async (resolve, reject) => {
    const db = await openDatabase();
    const transaction = db.transaction(["videos"], "readonly");
    const store = transaction.objectStore("videos");
    const request = store.getAll();

    request.onsuccess = (event: any) => {
      const videos = event.target.result.map((video: any) => ({
        id: video.id,
        name: video.name || video.id,
      }));
      resolve(videos);
    };
    request.onerror = () => reject("Error listing videos: " + request.error);
  });
}

export function deleteVideoFromIndexedDB(videoId: string): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const db = await openDatabase();
    const transaction = db.transaction(["videos"], "readwrite");
    const store = transaction.objectStore("videos");
    const request = store.delete(videoId);
    request.onsuccess = () => resolve();
    request.onerror = () => reject("Error deleting video: " + request.error);
  });
}
